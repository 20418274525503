body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

html {
  height: 100%;
}

#root {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Remove Google Map Credit (Right - Bottom) */
.gmnoprint a, .gmnoprint span {
  display:none;
}
.gmnoprint div {
  background:none !important;
}

.kumo-expand:hover {
  box-shadow: 0px 0px 6px 0px #000000ea !important;
  border-radius: 4px;
  transition: 100ms;
}

.red-hover:hover {
  color: red !important;
  transition: 100ms;
}

.kumo-expand {
  border-radius: 4px;
  transition: 200ms;
  padding-left: 4px;
  padding-right: 4px;
  color: #e5e5e5 !important;
}

.kumo-expanded-map-backdrop {
  position: fixed;
  left:0px; right: 0px;
  top: 0px; bottom: 0px;
  background-color: #00000055;
  z-index: 99 !important;
}

.kumo-expanded-map {
  box-shadow: 0px 0px 50px 6px #000000aa !important;
  position: fixed !important;
  left: 0px !important;
  right: 0px !important;
  top: 0px !important;
  bottom: 0px !important;
  height: 700px;
  border-radius: 8px !important;
  z-index: 100 !important;
  transition: 200ms !important;
  overflow: hidden !important;
  /* padding: 20px; */
  padding-bottom: 30px;
  border: 1px solid #303B70;
  /* animation: fade 700ms linear 1 forwards; */
  animation: shake 600ms;
  max-width: 1600px;
}

@keyframes fade {
  0% { opacity: 0.5; }
  70% { opacity: 0.9 }
  100% { opacity: 1 }
}

@keyframes shake {
  10%, 30% {
    transform: translate3d(0, -4px, 0);
  }
  30%, 100% {
    transform: translate3d(0, 0, 0);
  }
  /* 10%, 90% {
    transform: translate3d(-1px, 0, 0);
  } */
  /* 20%, 80% {
    transform: translate3d(2px, 0, 0);
  }
  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }
  40%, 60% {
    transform: translate3d(4px, 0, 0);
  } */
}

.kumo-expanded-map .map-container {
  padding: 2px;
  border-radius: 4px !important;
  box-shadow: 0px 0px 8px 1px #00000055 !important;
}

.kumo-expanded-map .site-info-container {
  border-radius: 4px !important;
  box-shadow: 0px 0px 8px 1px #00000055 !important;
}

#map-container > div > div > div > div {
  background-color: #1c275c !important;
  border: none !important;
}

.row {
  /* padding: 0px !important; */
  margin: 0px !important;
}

.col {
  padding: 0px !important;
  margin: 0px !important;
}

.wrapper { position: relative; }
i.fa-calendar { position: absolute; top: 10px; left: 13px; }

.dateInput {
  padding-left: 30px;
}

